// src/context/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import config from '../config';
import { AppContext } from '../redux/AppContext';
import {
    setErrorMessage,
    setProfile,
    setLoggedUsing,
    setToken,
    setCurrentChatId,
    setCurrentProject
} from '../redux/actions';

const environment = process.env.NODE_ENV || 'development';
const apiUrl = config[environment].apiUrl;

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const { dispatch, state } = useContext(AppContext);
    const { loggedUsing, token } = state;
    const [isAuthenticating, setIsAuthenticating] = useState(true); // To handle initial loading
    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);

    useEffect(() => {
        // console.log( 'CALL tryRecoverToken!');
        tryRecoverToken();
    }, [loggedUsing, instance, accounts]);

    const recoverToken = async () => {
        await tryRecoverToken();
    }

    const tryRecoverToken= async () => {
        if (token) return;
        if (loggedUsing === 'email') {
            const _token = localStorage.getItem('access-token-for-email');
            if (!_token) {
                return;
            }
            dispatch(setToken(_token));
            try {
                setIsAuthenticating(true);
                const response = await axios.post(apiUrl + '/register-from-token-for-email', {token: _token});
                dispatch(setProfile(response.data))
                setEmail(response.data?.email);
                setName(response.data?.name);
            } catch (error) {
                console.error("Register failed:", error);
                dispatch(setErrorMessage(error.message || "Register failed."));
                resetLogin();
            } finally {
                setIsAuthenticating(false);
            }

        } else if (instance && accounts && accounts.length> 0) {
            try {
                setIsAuthenticating(true);
                const tokenResponse = await instance.acquireTokenSilent({
                    scopes: config["msal-scope"],
                    account: accounts[0]
                });
                dispatch(setLoggedUsing('azure-ad'));
                dispatch(setToken(tokenResponse.accessToken));
                setEmail(accounts[0].username);
                setName(accounts[0].name);

                const response = await axios.post(apiUrl + '/register', { email: accounts[0].username, name: accounts[0].name });
                dispatch(setProfile(response.data))
                setIsAuthenticating(false);

            } catch (error) {
                console.error("Register failed:", error);
                dispatch(setErrorMessage(error.message || "Register failed."));
                resetLogin();
            } finally {
                setIsAuthenticating(false);
            }
        } else {
            setIsAuthenticating(false);
        }
    }

    const loginByAzureAd = async () => {
        const loginRequest = {
            scopes: ["User.Read"] // Adjust scopes as needed
        };
        try {
            setIsAuthenticating(true);
            const response = await instance.ssoSilent(loginRequest);
            dispatch(setLoggedUsing('azure-ad'));
            await recoverToken();
        } catch (error) {
            try {
                await instance.loginRedirect(loginRequest);
                await recoverToken();
            } catch (redirectError) {
                dispatch(setErrorMessage(redirectError.message || "SSO Login Redirect failed."));
            }
        } finally {
            setIsAuthenticating(false);
        }
    }

    const loginByEmail = async (email, password) => {
        try {
            setIsAuthenticating(true);
            const response = await axios.post(`${config.apiUrl}/login`, { email, password });
            const { access_token: accessToken, profile } = response.data;
            if (!accessToken) {
                dispatch(setErrorMessage("Invalid token"));
                return;
            }

            localStorage.setItem('access-token-for-email', accessToken);
            localStorage.setItem('logged-using', "email");
            dispatch(setLoggedUsing("email"));
            await recoverToken();

        } catch (error) {
            console.error('Email/Password login failed:', error);
            dispatch(setErrorMessage(error.response?.data?.message || error.message || 'Email/Password login failed.'));
        } finally {
            setIsAuthenticating(false);
        }
    };

    // Logout
    const logout = () => {
        localStorage.removeItem('access-token-for-email');
        localStorage.removeItem('logged-using');
        dispatch(setToken(null));
        dispatch(setCurrentChatId(null));
        dispatch(setCurrentProject(null));
        if (loggedUsing === 'azure-ad') {
            instance.logout().catch(e => {
                console.error('SSO Logout failed:', e);
            });
        }
        setLoggedUsing(null);
    };

    // Get Token
    const getToken = () => token;

    const resetLogin = () => {
        logout();
    }

    return (
        <AuthContext.Provider value={{ token, authMethod: loggedUsing, loginByAzureAd, loginByEmail, logout, getToken, isAuthenticating, name, email }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
