import AppRouter from "../AppRouter/AppRouter";
import React, {useContext, useEffect, useState} from "react";
import {getApi} from "../../services/apiService";
import {
    // setChatSlidingWindow,
    // setUsePremiumModel,
    setCurrentChatId,
    setCurrentProject,
    setProjectList,
    setShowNav, setDataStore, setModel, setAgent, setTemperature, setModelList
} from "../../redux/actions";
import {AppContext} from "../../redux/AppContext";
import {NavLink, useNavigate} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import styles from './TopLayout.module.css'
import {ThemeContext} from "../../redux/ThemeContext";
import MaxModal from "../MaxModal/MaxModal";
import DrawerListModal from "../DrawerListModal/DrawerListModal";
import ContextModal from "../ContextModal/ContextModal";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import {useApi} from "../../hooks/useApi";
import {FaAngleDown, FaCheck, FaSearch} from "react-icons/fa";
import {FaBars, FaCirclePlus, FaPlus, FaTemperatureThreeQuarters} from "react-icons/fa6";
import {LiaTemperatureLowSolid} from "react-icons/lia";
import {Title} from "../Headings/Heading";
import {GoTriangleDown} from "react-icons/go";

// Debounce function
let debounceTimer;
const debounce = (func, delay) => {
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
}


function TopLayout() {
    const { state, dispatch } = useContext(AppContext);
    const { useDataStore, useModel, useAgent, temperature, modelList } = state;
    const { projectList, currentProject, showNav, isMobile } = state;
    const { instance, accounts } = useMsal();
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    const [chatSearchText, setChatSearchText] = useState('');
    const [searchChatList, setSearchChatList] = useState([]);
    const [showModelsModal , setShowModelsModal] = useState(null);
    const [posModelModal, setPosModelModal] = useState({});
    const [showDataStoreModal, setShowDataStoreModal] = useState(null);
    const [dataStoreList, setDataStoreList] = useState([])
    // const [modelsList, setModelsList] = useState([])

    const [agentList, setAgentList] = useState([])
    const [showAgentModal , setShowAgentModal] = useState(null);

    // const temperatureList = Array.from({length: Math.ceil((2 - 0) / 0.1) + 1}, (_, i) => parseFloat((0 + i * 0.1).toFixed(1)));
    const [showTemperatureModal , setShowTemperatureModal] = useState(null);
    const api = useApi();


    useEffect(() => {
        api.get('/api/data-store')
            .then(response => {
                setDataStoreList(response.data);
            })
            .catch((error)=> { /*error handled in apiService*/});

        api.get('/api/models')
            .then(response => {
                dispatch(setModelList(response.data));
                const _default = response.data?.find(item=>item.is_default === true) || null;
                if (useModel) {
                    if (!response.data?.some(item=> item.name === useModel)) {
                        dispatch(setModel(_default?.name));
                    }
                } else {
                    dispatch(setModel(_default?.name));
                }
            })
            .catch((error)=> { /*error handled in apiService*/});

        api.get('/api/agents')
            .then(response => {
                setAgentList(response.data);
            })
            .catch((error)=> { /*error handled in apiService*/});

        api.get('/api/projects')
            .then(response => {
                dispatch(setProjectList(response.data));
            })
            .catch((error)=> { /*error handled in apiService*/});
    }, [accounts]);

    useEffect(() => {
        setSearchChatList([]);
    }, [currentProject]);

    useEffect(() => {
        if (projectList) {
            if (!currentProject) {
                dispatch(setCurrentProject(projectList[0]));
            } else {
                if (!projectList.map(p=>p.id).includes(currentProject.id)) {
                    dispatch(setCurrentProject(projectList[0]));
                }
            }
        } else {
            dispatch(setCurrentProject(null));
        }
    }, [projectList]);

    const handleChatSearch = debounce((text) => {
        api.get("/api/chats/search/fulltext", {
            params: {
                project_id: currentProject.id,
                q: text
            }
        })
            .then(response => {
                setSearchChatList(response.data);
            })
            .catch((error)=> { /*error handled in apiService*/});
    }, 500); // 500ms delay


    const handleChatHistoryClick = async (chatId) => {
        await dispatch(setCurrentChatId(chatId))
        navigate(`/chat/${chatId}`)
    }

    const selectDataStore = async (dataStoreName) => {
        await dispatch(setDataStore(dataStoreName));
        setShowDataStoreModal(null);
    }

    const dataStoreModal = !!showDataStoreModal && (
        <ContextModal
            // show={true}
            clickPosition = {showDataStoreModal}
            handleClose={(e)=> setShowDataStoreModal(null)}
            // style={posModelModal}
        >
            <div className={styles["data-selection-list"]}>
                <div className={styles["data-selection-item"]}>
                    <div className={styles["data-store-selected"]}></div>
                    <div className={styles["data-selection-item-text"]} onClick={()=> selectDataStore(null)}>remove selection</div>
                </div>
                {dataStoreList.map((d, index) => (
                    <div key={index} className={styles["data-selection-item"]}>
                        <div className={styles["data-store-selected"]}>
                            {useDataStore && d.name == useDataStore[0] && <img src ="/icons8-checked-48.png" alt="selected"/>}
                        </div>
                        <div className={styles["data-selection-item-text"]} onClick={()=> {
                            selectDataStore([d.name,d.source]);
                        }}>{d.source}</div>
                    </div>
                ))}
            </div>
        </ContextModal>
    )


    const modelsModal = !!showModelsModal && (
        <ContextModal
            // show={true}
                      clickPosition = {showModelsModal}
                      handleClose={(e)=> setShowModelsModal(null)}>
            <div className={styles["data-selection-list"]}>
                {modelList.map((d, index) => (
                    <div key={index} className={styles["data-selection-item"]}>
                        <div className={styles["data-store-selected"]}>
                            {useModel && d.name === useModel && <div className={"fa-icon -accept"}><FaCheck/></div>}
                        </div>
                        <div className={styles["data-selection-item-text-lines"]} onClick={()=> {
                            dispatch(setModel(d.name));
                            setShowModelsModal(null);
                        }}>
                            <div className={styles["title-row"]}>
                                <img src={d.company==="OPENAI"? "/openai.png": (d.company==="MISTRAL"?"/mistral.png": "/gemini.png")}/>
                                <div className={styles["title"]}>{d.name}</div>
                            </div>
                            <div className={styles["data-selection-item-description"]}>{d.description}
                            </div>
                            {d.input_price && <span className={styles["models-annotation"]}><span className={styles["models-field"]}>input/output price (per 1 million token): </span>${d.input_price} / ${d.output_price}</span>}
                            {d.unit_price && <span className={styles["models-annotation"]}><span className={styles["models-field"]}>unit price: </span>${d.unit_price}</span>}
                            {d.max_token && <span className={styles["models-annotation"]}><span className={styles["models-field"]}>max token: </span>{d.max_token}</span>}
                            {d.knowledge_cutoff && <span className={styles["models-annotation"]}><span className={styles["models-field"]}>knowledge_cutoff: </span>{d.knowledge_cutoff}</span>}
                            {d.is_vision_enabled && <span className={styles["models-annotation"]}><span className={styles["models-field"]}>vision enabled</span></span>}
                        </div>
                    </div>
                ))}
                {/*<div className={styles["data-selection-footnotes"]}>updated from https://openai.com/pricing and https://platform.openai.com/docs/models in 2024/01/29</div>*/}
            </div>
        </ContextModal>
    )

    const temperatureModal = !!showTemperatureModal && (
        <ContextModal
            // show={true}
            clickPosition = {showTemperatureModal}
            handleClose={(e)=> setShowTemperatureModal(null)}>
            <div className={styles["temperature-modal-container"]}>
                <Slider
                    min={0}
                    max={2}
                    step={0.1}
                    defaultValue={temperature || .1}
                    onChange={(value) => {
                        value = value.toFixed(1);
                        dispatch(setTemperature(value));
                    }}
                />
                <div>Current Value: {temperature === null ? "default" : temperature}</div>
                <div className={styles["panel"]}>
                <button className={"button"} onClick={() => {
                    dispatch(setTemperature(null))
                }}>reset to default
                </button>
                <button className={"button"} onClick={() => {
                    setShowTemperatureModal(null);
                }}>close
                </button>
                </div>
                <div className={styles["temperature-description"]}>
                    What sampling temperature to use, between 0 and 2. Higher values like 0.8 will
                    make the output more random, while lower values like 0.2 will make it more
                    focused and deterministic
                </div>

            </div>
        </ContextModal>
    )


    const agentModal = !!showAgentModal && (
        <ContextModal
            clickPosition = {showAgentModal}
            // show={true}
            handleClose={(e)=> setShowAgentModal(null)}>
            <div className={styles["data-selection-list"]}>
                <div className={styles["data-selection-item"]}>
                    <div className={styles["data-store-selected"]}></div>
                    <div className={styles["data-selection-item-text"]} onClick={()=> {
                        dispatch(setAgent(null));
                        setShowAgentModal(null);
                    }}>remove selection</div>
                </div>
                {agentList.map((d, index) => (
                    <div key={index} className={styles["data-selection-item"]}>
                        <div className={styles["data-store-selected"]}>
                            {useAgent && d.name == useAgent && <img src ="/icons8-checked-48.png" alt="selected"/>}
                        </div>
                        <div className={styles["data-selection-item-text-lines"]} onClick={()=> {
                            dispatch(setAgent(d.name));
                            setShowAgentModal(null);
                        }}>
                            <div className={styles["title"]}>{d.name}</div>
                            <div className={styles["data-selection-item-description"]}>{d.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </ContextModal>
    )



    const chatSearchModal = !!isSearchModalOpen && (
        <ContextModal
            // show={isSearchModalOpen}
            clickPosition={isSearchModalOpen}
            nonblocking={true}
            handleClose={()=> setSearchModalOpen(null)}
            closeLabel={"Close"}>
            <div className={styles["search-modal-list-container"]}>
                <div className={styles["model-list-search-container"]}>
                    Search: <input type={"text"}
                                   value={chatSearchText}
                                   className={`${styles["modal-list-search-input"]} input`}
                                   autoFocus
                                   onChange = {e=> {
                                       setChatSearchText(e.target.value);
                                       handleChatSearch(e.target.value);
                                   }}/>
                </div>
                <div className={styles["model-list-search-result-container"]}>
                    <div className={styles["model-list-search-result-inner-scroll"]}>
                        {searchChatList.map((chat, index)=> (
                            <div className={"selection-list-item"} key={index} onClick =
                                {() => {
                                    handleChatHistoryClick(chat.id);
                                    if (isMobile)
                                        setSearchModalOpen(null);
                                }}>
                                <div className={styles['modal-list-title']}>{chat.title}</div>
                                {/*<div className={`${styles['modal-list-date']} code-view`}>{chat.startTime.slice(0,10)}</div>*/}
                                {/*<div className={`${styles['modal-list-content']} code-view`}>{chat?.content}</div>*/}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ContextModal>
    )

    const newChatHandler = async () => {
            await dispatch(setCurrentChatId(null))
            navigate(`/chat`)
        }


    return (
        <div className={`${styles["app-top"]}`}>
            <div className={styles["top-level-header"]}>
                <div className={styles["header-left"]}>
                    <div className={"fa-icon -larger"} onClick={()=> dispatch(setShowNav(!showNav))}>
                        <FaBars />
                        {/*<img src ={theme == "dark"? "/icons8-menu-50-dark.png": "/icons8-menu-50-light.png"} alt="nav"/>*/}
                    </div>
                    {/*<Title onClick={newChatHandler} className={styles["top-title"]}>OpBoost</Title>*/}
                </div>
                {/*<div className={styles["header-middle"]} onClick={()=> navigate(`/workspace`)*/}
                {/*}>*/}
                {/*    {currentProject?.name}*/}
                {/*</div>*/}
                <div className={styles["header-right"]}>
                    {currentProject && (
                        <>
                            <div className={`pointer-icon-no-width ${styles["use-model"]}`}
                                 onClick={(e) => {
                                     const rect = e.target.getBoundingClientRect();
                                     setShowModelsModal({top: rect.top, left: rect.left})
                                 }} title="use model">
                                {useModel || "default"}
                                <GoTriangleDown size={20}/>
                                {/*<img*/}
                                {/*    src={theme == "dark" ? "/icons8-sort-down-50--dark.png" : "/icons8-sort-down-50--light.png"}/>*/}
                            </div>
                            <div className={styles["temperature"]}
                                 onClick={(e) => {
                                     const rect = e.target.getBoundingClientRect();
                                     setShowTemperatureModal({top: rect.top, left: rect.left})
                                 }} title="Temperature">
                                <div className={"fa-icon -larger"}>
                                    <LiaTemperatureLowSolid />
                                </div>
                                {/*<img*/}
                                {/*    src={theme == "dark" ? "/icons8-temperature-60--dark.png" : "/icons8-temperature-60--light.png"}/>*/}
                                {temperature === null ? "" : temperature}
                            </div>

                            {/*<div className={"pointer-icon"}*/}
                            {/*     onClick={() => dispatch(setChatSlidingWindow(!chatSlidingWindow))}*/}
                            {/*     title="Enable/disable sliding window">*/}
                            {/*    <img*/}
                            {/*        src={theme == "dark" ?*/}
                            {/*            (chatSlidingWindow ? "/icons8-window-shade-50-checked-dark.png" : "/icons8-window-shade-50-unchecked-dark.png")*/}
                            {/*            :*/}
                            {/*            (chatSlidingWindow ? "/icons8-window-shade-50-checked-light.png" : "/icons8-window-shade-50-unchecked-light.png")}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={"pointer-icon"}*/}
                            {/*     onClick={(e) => {*/}
                            {/*         const rect = e.target.getBoundingClientRect();*/}
                            {/*         setShowAgentModal({top: rect.top, left: rect.left})*/}
                            {/*     }} title="AI assistant">*/}
                            {/*    <img*/}
                            {/*        src={theme == "dark" ?*/}
                            {/*            (!!useAgent ? "/icons8-robot-50--dark-checked.png" : "/icons8-robot-50--dark.png")*/}
                            {/*            :*/}
                            {/*            (!!useAgent ? "/icons8-robot-50--light-checked.png" : "/icons8-robot-50--light.png")}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={"pointer-icon"}*/}
                            {/*     onClick={(e) => {*/}
                            {/*         const rect = e.target.getBoundingClientRect();*/}
                            {/*         // setPosModelModal({top: `${rect.top}px`, right: `calc(100% - ${rect.left}px)`});*/}
                            {/*         setShowDataStoreModal({top: rect.top, left: rect.left})*/}
                            {/*         // setShowDataStoreModal(true);*/}
                            {/*     }} title="use data store">*/}
                            {/*    <img*/}
                            {/*        src={theme == "dark" ?*/}
                            {/*            (!!useDataStore ? "/icons8-database-50-checked-dark.png" : "/icons8-database-50-unchecked-dark.png")*/}
                            {/*            :*/}
                            {/*            (!!useDataStore ? "/icons8-database-50-checked-light.png" : "/icons8-database-50-unchecked-light.png")}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className={"fa-icon -larger"} onClick={async (e) => {
                                const rect = e.target.getBoundingClientRect();
                                setSearchChatList([]);
                                setChatSearchText(null);
                                setSearchModalOpen({top: rect.top, left: rect.left})
                            }}
                                 title={"Search"}
                            >
                                <FaSearch/>
                                {/*<img*/}
                                {/*    src={theme == "dark" ? "/icons8-search-50-dark.png" : "/icons8-search-50-light.png"}*/}
                                {/*    alt="search"/>*/}
                            </div>

                            <div className={"fa-icon -blue"} onClick={newChatHandler}
                                 title={"New chat"}
                            >
                                <FaPlus/>

                                {/*<img*/}
                                {/*    src={theme == "dark" ? "/icons8-plus-50-fill--dark.png" : "/icons8-plus-50-fill--light.png"}*/}
                                {/*    alt="new chat"/>*/}
                            </div>

                        </>
                    )}
                </div>
            </div>
            <div className={styles["top-level-main"]}>
                {currentProject && projectList && <AppRouter/>}
            </div>


            {isSearchModalOpen && chatSearchModal}
            {/*{modelListModal}*/}
            {dataStoreModal}
            {modelsModal}
            {agentModal}
            {temperatureModal}
        </div>
    )
}

export default TopLayout;
