// src/components/ContextArtifact/SystemMessage.js
import React, {useState, useEffect, useContext} from 'react';
import {AppContext} from "../../redux/AppContext";
import styles from "./SharedChat.module.css"
import { useParams } from 'react-router-dom';
import {ThemeContext} from "../../redux/ThemeContext";
import config from "../../config"
import axios from 'axios';
import {setErrorMessage, setInfoMessage, setUseMaximize} from "../../redux/actions";
import {getApi} from "../../services/apiService";
import MaxModal from "../MaxModal/MaxModal";
import CodeWithHighlighting from "../CodeWithHighlighting/CodeWithHighlighting";
import FullMarkdown from "../FullMarkdown/FullMarkdown";
import {FaDownload} from "react-icons/fa6";
import {FaMoon, FaSun} from "react-icons/fa";
// import {AppContext} from "../../redux/AppContext";

function SharedChat() {
    const { guid } = useParams();
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);
    const { state, dispatch } = useContext(AppContext);
    const [augmentedMessage, setAugmentedMessage] = useState(null);
    const [showAugmentedMessage , setShowAugmentedMessage] = useState(false);
    const { theme, switchTheme } = useContext(ThemeContext);

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);


    useEffect(() => {
        dispatch(setUseMaximize(true));

        setContent({
            title: "Titulo "+ guid,
            description: "Description"
        })
        axios.get(`${config.apiUrl}/api/public/shared_chats/${guid}/thread`)
            .then(response => {
                setContent(response.data);
            })
            .catch(error => {
                if (error.response?.data?.detail)
                    setError(error.response?.data?.detail)
                else
                    setError(error.message);
            });
    }, [guid]);

    const sanitizeFilename = (name) => {
        return name.replace(/[/\\?%*:|"<>]/g, '-');
    }
    const downloadChat = (text, filename) => {
        if (!content.entries) return;

        text = content.entries.map(m => `${m.role}: ${m.content}`).join('\n\n');

        // Create a blob of the text
        const blob = new Blob([text], { type: 'text/plain' });

        // Create a link to download the blob
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = "Opus Boost - "+ sanitizeFilename(content.title || "Chat")+ ".txt";

        // Append the link to the body, click it, and then remove it
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const header = (
        <>
            <div className={styles["header-row"]}>
                <h3 className={styles["header"]}
                    onClick={() => {
                        window.location.href = config.frontendUrl;
                    }}
                >Opus Boost</h3>
                <div className={styles["header-buttons"]}>
                    <div className="icon-button-smaller"
                         onClick={switchTheme}>
                        {theme === "dark" ? <FaSun title="Light mode" /> : <FaMoon title="Dark mode" />}
                        {/*<img*/}
                        {/*    src={theme == "dark" ? "/icons8-light-mode-78.png" : "/icons8-dark-mode-50.png"}*/}
                        {/*    title="dark/light mode"/>*/}
                    </div>
                    <div onClick={() => downloadChat()}
                         className="fa-icon" title={"download"}>
                        <FaDownload/>
                        {/*<img*/}
                        {/*    src={theme == "dark" ? "/icons8-download-50--dark.png" : "/icons8-download-50--light.png"}*/}
                        {/*    alt="Download"/>*/}
                    </div>
                </div>
            </div>
            <div className={styles["header-line"]}></div>
        </>
    )

    if (error) {
        return (
            <div className={styles['container']}>
                {header}
                <div className={styles["error"]}>{error}</div>
            </div>)
    }

    const handleCopyContent = (content) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(content).then(() => {
                console.log('Content copied to clipboard');
                dispatch( setInfoMessage('Content copied to clipboard'));

                // Optionally, you can display a message to the user indicating the copy was successful.
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        } else {
            setErrorMessage( "Not supported in this browser");
        }
    };

    const augmentedMessageModal = showAugmentedMessage && (
        <MaxModal show={true} handleClose={()=> setShowAugmentedMessage(false)}>
            <>
                <div className={styles["augmented-modal-panel"]}>
                    <div onClick={() => handleCopyContent(augmentedMessage.content)}
                         className="icon-button-smaller" title={"copy chat entry"}>
                        <img
                            src={"/icons8-copy-50--dark.png"}
                            alt="Copy"/>
                    </div>
                </div>
                {augmentedMessage.meta && (
                    <>
                        <div className={`${styles["augmented-message-modal-container"]} code-view`}>
                            {JSON.stringify(augmentedMessage.meta, null, 2)}
                        </div><hr className={"separator"}/></>)}
                <div className={`${styles["augmented-message-modal-container"]} code-view`}>
                    {augmentedMessage.content}
                </div>
            </>
        </MaxModal>
    )



    const handleDisplayAugmented = async (id) => {
        axios.get(`${config.apiUrl}/api/public/augmented-message/${id}`)
            .then(response => {
                setAugmentedMessage( response.data);
                setShowAugmentedMessage( true);
            })
            .catch(error => {
                if (error.response?.data?.detail)
                    alert(error.response?.data?.detail)
                else
                    alert(error.message);
            });
    }


    return (
        <div className={styles['container']}>
            {header}
            {content && content.entries && (
                <div>
                    <h1>{content.title}</h1>
                    <div className={styles["published"]}>
                        Created {new Date(content.created_at).toString()}. Expires {new Date(content.shared_id_expire_date).toDateString()}</div>
                    <div className={`code-view-in-chat ${styles["chat-conversation-top"]}`}>
                        {content.entries.map((m, index) => (
                            <div key={index} className={`${styles["message-entry-line"]} ${styles[m.role]} markdown`}>
                                {/*<span className={styles["role"]}>{m.role}</span>*/}
                                {m.role === "assistant" ? (
                                    <div className={styles["markdown-parent"]}><FullMarkdown content={m.content}/></div>
                                        // <TextWithFormulas text={message.content}/>
                                ) : (
                                    m.content
                                )}
                                {/*{m.content}*/}
                                {m.image_url && (
                                    <div>
                                        <a href={m.image_url} target="_blank" rel="noopener noreferrer">
                                            <img className={styles["image-in-thread"]} src={m.image_url}/>
                                        </a>
                                    </div>
                                )}
                                <div className={styles["message-footer"]}>
                                    {m.augmented_message_log_id && (<>
                                        <div
                                            onClick={() => handleDisplayAugmented(m.augmented_message_log_id)}
                                            className="icon-button-smaller"
                                            title={"view augmented context for this question"}>
                                            <img
                                                src={"/icons8-renew-40--dark.png"}/>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {augmentedMessageModal}
        </div>
    );
}

export default SharedChat;
