import Login from "./components/Login/Login";
import TopMostLayout from "./components/TopMostLayout/TopMostLayout";
import Loading from "./components/Loading/Loading";

import { useAuth } from './context/AuthContext';

function AuthenticatedApp() {
    const { token, isAuthenticating } = useAuth();

    if (isAuthenticating) {
        // return <Loading />;
        return <Login />;
    }
    return token? <TopMostLayout />: <Login/>;
}

export default AuthenticatedApp;
