import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './Login.module.css';
import config from "../../config";
import {setRetryLogin, setForceLogin, setErrorMessage} from "../../redux/actions";
import {AppContext} from "../../redux/AppContext";
import { useMsal } from "@azure/msal-react";
import { useAuth } from '../../context/AuthContext';
import Loading from "../Loading/Loading";

function Login() {
    const { state, dispatch } = useContext(AppContext);
    const { instance } = useMsal();

    const { loginByAzureAd, loginByEmail, isAuthenticating } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    const isLoadingRef = useRef(isAuthenticating);

    useEffect(() => {
        isLoadingRef.current = isAuthenticating;
    }, [isAuthenticating]);


    const handleLoginByAzureAd = async (e) => {
        e.preventDefault();
        await loginByAzureAd();
    };

    const handleLoginByEmail = async (e) => {
        e.preventDefault();
        await loginByEmail(email, password);
    };


    return (
        <>
            {isAuthenticating? <div className={styles["loading"]}>
                <Loading/></div>
            :
        <div className={styles["login-container"]}>
            <h1>Welcome to Opus Boost!</h1>
            <div className={styles["login-form"]}>
                <div className={styles["login-group"]}>
                    <button onClick={handleLoginByAzureAd} className={`${styles["submit-btn"]} button`}
                            disabled={isAuthenticating}>
                        {isAuthenticating ? 'Logging in...' : 'Continue with Microsoft'}
                    </button>
                </div>
                <div className={styles["separator"]}>
                    <span>or</span>
                </div>
                <div className={styles["login-group"]}>
                    <div >
                        <div className={styles["form-group"]}>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles["form-group"]}>
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className={`${styles["submit-btn"]} button`} disabled={isAuthenticating}
                                onClick={handleLoginByEmail}
                        >
                            {isAuthenticating ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </div>
            </div>

        </div>}
        </>

    );
}

export default Login;
