import styles from "./MaxModal.module.css"
import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../redux/ThemeContext";
import { AppContext } from '../../redux/AppContext'; // import AppContext
import {setUseMaximize} from "../../redux/actions";
import {FaXmark} from "react-icons/fa6";
import {FaCompressAlt, FaExpandAlt} from "react-icons/fa";

function MaxModal({ handleClose, handleSave, show, children, closeLabel }) {
    // const [useMaximize, setMaximize] = useState(false);
    const { theme } = useContext(ThemeContext);
    const { state, dispatch } = useContext(AppContext);
    const { isMobile, useMaximize } = state;

    useEffect(()=> {
        if (isMobile) {
            dispatch(setUseMaximize(true));
        }
    }, [isMobile])

    const showHideClassName = show ? `${styles["modal"]} ${styles["display-block"]}` :
        `${styles["modal"]} ${styles["display-none"]}`;

    return (
        <div className={showHideClassName} onMouseDown={handleClose}>
            <div className={useMaximize? `${styles["modal-container-maximize"]}`:`${styles["modal-container"]}`}
                 onMouseDown={(e)=> e.stopPropagation()}>
                <div className={styles["modal-nav"]}>
                    {!useMaximize && <>
                        <div className="fa-icon"
                            onClick={handleClose}>
                                <FaXmark/>
                                {/*<img*/}
                                {/*    src ={theme == "dark"? "/icons8-close-50-dark.png": "/icons8-close-50-light.png"}*/}
                                {/*/>*/}
                            {/*</a>*/}
                        </div>
                        <div className="fa-icon"
                            onClick={() => dispatch(setUseMaximize(true))}>
                                <FaExpandAlt/>
                                {/*<img*/}
                                {/*    src ={theme == "dark"? "/icons8-maximize-arr-50-dark.png": "/icons8-maximize-arr-50-light.png"}*/}
                                {/*/>*/}
                            {/*</a>*/}
                        </div>
                    </>
                    }
                    {useMaximize && <>
                        <div className="fa-icon -larger-x" onClick={handleClose}>
                                <FaXmark/>
                                {/*<img*/}
                                {/*    src ={theme == "dark"? "/icons8-close-50-dark.png": "/icons8-close-50-light.png"}*/}
                                {/*/>*/}
                            {/*</a>*/}
                        </div>
                        <div className="fa-icon -larger-x"
                            onClick={() => dispatch(setUseMaximize(false))}>
                                <FaCompressAlt />
                                {/*<img*/}
                                {/*    src ={theme == "dark" || useMaximize? "/icons8-compress-arr-50-dark.png": "/icons8-compress-arr-50-light.png"}*/}
                                {/*/>*/}
                            {/*</a>*/}
                        </div>
                    </>}
                </div>
                <div className={styles["modal-container-inner"]}>
                    {children}
                </div>
                {/*<div className={styles["modal-actions"]}>*/}
                {/*    /!*<button onClick={handleClose}>{closeLabel || "Cancel"}</button>*!/*/}
                {/*    {handleSave && <button onClick={handleSave}  className="button">Save</button>}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default MaxModal;

